@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

// If you want to override variables do it here
@import "variables";

$enable-ltr: false;
$enable-rtl: true;
$font-family-sans-serif: "Almarai";
// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
