// Here you can add other styles
.w-0 {
  width: 0;
  white-space: nowrap;
}

td {
  vertical-align: middle;
}

.btn.btn-danger,
.btn.btn-success {
  color: #fff !important;
}

.Toastify__toast {
  font-family: inherit !important;
}

.grid-drag {
  direction: ltr !important;
  display: grid;
  grid-template-columns: repeat(11, 1fr);

  .grid-item {
    display: inline-block;
    width: 80px;
    height: 100px;
    text-align: center;
    margin: 20px 0;

    img {
      object-fit: contain;
    }

    button {
      //   opacity: 0;
      margin: 10px 3px;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
